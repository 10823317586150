<template>
  <b-card no-body class="card-developer-meetup">
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <validation-observer ref="simple">
        <b-card-code>
          <b-form>
            <b-row>
              <!-- <div > -->
              <b-col md="3" xl="3">
                <validation-provider #default="{ errors }">
                  <b-form-group
                    label-for="Inputperson"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>الموظف</label>
                    <v-select
                      v-model="receiver_id"
                      :reduce="(val) => val.value"
                      label="label"
                      :options="userList"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="3" xl="4">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ </label>
                    <flat-pickr
                      v-model="date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="meetup-header d-flex align-items-center">
              <div class="my-auto">
                <b-card-title class="mb-2"> </b-card-title>
                <!-- <b-card-text class="mb-0"> -->

                <!-- </b-card-text> -->
              </div>
            </div>
            <!--/ metting header -->
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label-for="notes"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="title"
                  placeholder="الرسالة"
                  rows="2"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form>
        </b-card-code>
        <br />

        <b-button variant="purple" @click="save" v-if="!isLoading">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle"> ارسال الرسالة</span>
        </b-button>
        <b-button v-if="isLoading" variant="purple" disabled class="mr-1">
          Loading...
        </b-button>
      </validation-observer>
    </b-card-body>
    <b-col class="col-2 text-align-start mb-2">
      <b-button variant="outline-primary" @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormGroup,
  BImg,
  BButton,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BForm,
  BMediaBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
/* eslint-disable global-require */
export default {
  components: {
    required,
    flatPickr,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BFormInput,
    BFormTextarea,
    BCol,
    vSelect,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BCardCode,
    BFormGroup,
    BFormTextarea,
    BButton,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      date: "",
      title: "",
      beneficaryName: "",
      beneficaryNumber: "",
      isLoading: false,
      selectedRel: "",
      CardNumber: "",
      beneficaryId: "",
      receiver_id: "",
      user_id:"",
      userData:"",
      userList: [],
      beneficaryList: [],
      getBeneficiaryByCard: false,
      optionPerson: [
        { label: "شخص مسجل ", value: 1 },
        { label: "شخص غير مسجل ", value: 2 },
      ],
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  created() {
    this.userName = localStorage.getItem("userName");
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // console.log("this.userData ", this.userData.id)
this.user_id = this.userData.id;
    this.$http.get("/api/v1/get-all-users").then((res) => {
      res.data.forEach((el) => {
        this.userList.push({
          value: el.id,
          label: `${el.first_name} ${el.last_name} `,
        });
      });
      // console.log(this.optionemploye);
    });
  },
  methods: {
    save() {
      this.$refs.simple.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          let data = {
            receiver_id: this.receiver_id,
            title: this.title,
            date: this.date,
          }
if(this.user_id === this.receiver_id){
  this.isLoading = false;
  this.$swal({
                title: "لا يمكنك إرسال الرسالة لنفسك",

                icon: "error",
                confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
              });
}

else{
  this.$http
            .post("/api/v1/conversations", data)
            .then((res) => {
              this.isLoading = false;
              this.title = "";
              this.date = "";
              this.receiver_id = "";

              requestAnimationFrame(() => {
                this.$refs.simple.reset();
              });
              this.$swal({
                title: "تم إضافة الرسالة بنجاح",

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.$swal({
                title: `${error.response.data.message}`,

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
}
         
          //console.log(data);

         
        }
      });
    },
  },
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  width: 200px;
  background-color: #0971b8 !important;
}
</style>
